import { classesToClassList } from "@components/solid/classLists";
import type { ScrubberValues } from "@nrk/player-core";
import { clamp } from "@utils/clamp";
import { formatSecondsToHoursMinutesAndSeconds } from "@utils/date";
import dayjs from "dayjs";
import {
  createEffect,
  createSignal,
  onCleanup,
  type Component,
} from "solid-js";
import styles from "./Scrubber.module.css";

type ScrubberProps = ScrubberValues & {
  isLive: boolean;
  colorVariant: "red" | "blue";
  onScrubberChange: (position: number) => void;
};

let scrubberWrapperRef: HTMLDivElement | null = null;
let inputRangeRef: HTMLInputElement | null = null;
let inputWrapperRef: HTMLDivElement | null = null;
let pointerLabelRef: HTMLParagraphElement | null = null;

const [pointerLabelPosition, setPointerLabelPosition] = createSignal<{
  x: number;
  y: number;
}>({ x: 0, y: 0 });

const [pointerValue, setPointerValue] = createSignal<number>(0);

export const Scrubber: Component<ScrubberProps> = (props) => {
  createEffect(() => {
    const handlePointerEvents = (event: PointerEvent) => {
      setPointerValue(
        clamp(
          calculateAproxPositionFromPointerEvent(
            inputRangeRef,
            event,
            getThumbWidth(),
          ),
          props.startPosition,
          props.endPosition,
        ),
      );
      const nextPointerLabelPosition = calculatePointerLabelPosition(
        event,
        pointerLabelRef,
      );
      if (nextPointerLabelPosition) {
        setPointerLabelPosition(nextPointerLabelPosition);
      }
    };

    inputRangeRef?.addEventListener("pointermove", handlePointerEvents);
    inputRangeRef?.addEventListener("pointerdown", handlePointerEvents);
    inputRangeRef?.addEventListener("pointerup", handlePointerEvents);

    onCleanup(() => {
      inputRangeRef?.removeEventListener("pointermove", handlePointerEvents);
      inputRangeRef?.removeEventListener("pointerdown", handlePointerEvents);
      inputRangeRef?.removeEventListener("pointerup", handlePointerEvents);
    });
  });

  // Add hover effect to the scrubber when the pointer enters the input range
  // CSS hover does not work well on touch devices
  createEffect(() => {
    const handlePointerEnter = (event: PointerEvent) => {
      const element = event.target as HTMLElement;
      element.classList.add(styles.scrubberActive);
    };
    const handlePointerLeave = (event: PointerEvent) => {
      const inputElement = event.target as HTMLElement;
      inputElement.classList.remove(styles.scrubberActive);
    };

    scrubberWrapperRef?.addEventListener("pointerenter", handlePointerEnter);
    scrubberWrapperRef?.addEventListener("pointerleave", handlePointerLeave);

    onCleanup(() => {
      scrubberWrapperRef?.removeEventListener(
        "pointerenter",
        handlePointerEnter,
      );
      scrubberWrapperRef?.removeEventListener(
        "pointerleave",
        handlePointerLeave,
      );
    });
  });

  return (
    <div
      class={styles.scrubberWrapper}
      classList={{ [styles.red]: props.colorVariant === "red" }}
      ref={(el) => (scrubberWrapperRef = el)}
    >
      <p
        classList={{
          ...classesToClassList("nrk-typography-caption--1", styles.timestamp),
          [styles.timestampShade]: props.isLive,
        }}
      >
        {props.isLive
          ? dayjs(props.startLiveTime).format("HH:mm:ss")
          : formatSecondsToHoursMinutesAndSeconds(props.playheadDisplayTime)}
      </p>
      <div
        ref={(el) => (inputWrapperRef = el)}
        class={styles.inputRangeWrapper}
      >
        <p
          ref={(el) => (pointerLabelRef = el)}
          id="pointerLabel"
          class={`nrk-typography-caption--1 ${styles.timestamp} ${styles.pointerLabel}`}
          style={{
            top: `${pointerLabelPosition().y}px`,
            left: `${pointerLabelPosition().x}px`,
          }}
        >
          {props.isLive
            ? formatTimestampLive(pointerValue(), props.endPosition)
            : formatSecondsToHoursMinutesAndSeconds(pointerValue())}
        </p>
        <input
          class={styles.inputRange}
          ref={(el) => (inputRangeRef = el)}
          type="range"
          min={props.startPosition}
          max={props.endPosition}
          step={0.5}
          value={props.playheadPosition}
          onInput={(event) => {
            setPointerValue(event.currentTarget.valueAsNumber);
            props.onScrubberChange(event.currentTarget.valueAsNumber);
          }}
        />
      </div>
      <p
        classList={{
          ...classesToClassList("nrk-typography-caption--1", styles.timestamp),
          [styles.timestampShade]: !props.isLive,
        }}
      >
        {props.isLive
          ? dayjs(props.endLiveTime).format("HH:mm:ss")
          : formatSecondsToHoursMinutesAndSeconds(props.endDisplayTime)}
      </p>
    </div>
  );
};

function calculateAproxPositionFromPointerEvent(
  inputRange: HTMLInputElement | null,
  event: PointerEvent,
  thumbWidth: number,
): number {
  if (!inputRange) {
    return 0;
  }
  const min = parseFloat(inputRange.min);
  const max = parseFloat(inputRange.max);
  const step = parseFloat(inputRange.step) || 1;
  const inputBoundingClientRect = inputRange.getBoundingClientRect();
  const pointerPosition = event.clientX - inputBoundingClientRect.left;

  // Adjust for the thumb width
  const adjustedPointerPosition = pointerPosition - thumbWidth / 2;

  // Ensure the pointer position is within the bounds of the input range
  const clampedPointerPosition = clamp(
    adjustedPointerPosition,
    0,
    inputBoundingClientRect.width - thumbWidth,
  );

  // Calculate the percentage of the pointer position relative to the range width
  const percentage =
    clampedPointerPosition / (inputBoundingClientRect.width - thumbWidth);

  // Calculate the position based on the percentage
  const position = min + percentage * (max - min);

  // Round the position to the nearest step
  return Math.round(position / step) * step;
}

function calculatePointerLabelPosition(
  event: PointerEvent,
  pointerLabel: HTMLParagraphElement | null,
): { x: number; y: number } | undefined {
  const inputElement = event.target as HTMLInputElement;
  if (!inputElement || !pointerLabel) {
    return;
  }
  const labelWidth = pointerLabel.offsetWidth;
  const labelHeight = pointerLabel.offsetHeight;
  const inputTop = inputElement.getBoundingClientRect().top;

  const y = inputTop - labelHeight;
  const x = event.clientX - labelWidth / 2;
  return { x, y };
}

function formatTimestampLive(seconds: number, endPosition: number): string {
  return dayjs()
    .subtract(endPosition, "seconds")
    .add(seconds, "seconds")
    .format("HH:mm:ss");
}

function getThumbWidth(): number {
  if (inputWrapperRef === null) return 16;

  const thumbWidth = parseFloat(
    getComputedStyle(inputWrapperRef).getPropertyValue("--thumb-size"),
  );
  return thumbWidth;
}
