import { Dialog } from "@components/solid/Player/controls/PlayerDialog/Dialog";
import { DialogButton } from "@components/solid/Player/controls/PlayerDialog/DialogButton";
import { calculateDialogPosition } from "@components/solid/Player/lib/calculateDialogPosition";
import {
  createEffect,
  type JSX,
  onCleanup,
  type ParentComponent,
} from "solid-js";

const PlayerDialog: ParentComponent<{
  buttonContent: string;
  onButtonClick?: JSX.EventHandler<HTMLButtonElement, Event>;
  dialogRef?: (el: HTMLDialogElement) => void;
  dialogClass?: string;
}> = (props) => {
  let dialogRef: HTMLDialogElement | undefined;
  let buttonRef: HTMLButtonElement | undefined;

  createEffect(() => {
    window.addEventListener("resize", handleResize);
    onCleanup(() => {
      window.removeEventListener("resize", handleResize);
    });
  });

  function handleResize() {
    if (dialogRef && buttonRef) {
      const { top, left } = calculateDialogPosition(buttonRef, dialogRef);
      dialogRef.style.top = top;
      dialogRef.style.left = left;
    }
  }
  return (
    <>
      <DialogButton
        buttonContent={props.buttonContent}
        ref={(el) => (buttonRef = el)}
        onClick={(event) => {
          props.onButtonClick?.(event);
          if (!dialogRef) return;

          const { top, left } = calculateDialogPosition(
            event.currentTarget,
            dialogRef,
          );

          dialogRef.style.top = top;
          dialogRef.style.left = left;

          dialogRef.showModal();
        }}
      />
      <Dialog
        ref={(el) => {
          dialogRef = el;
          props.dialogRef?.(el);
        }}
        class={props.dialogClass}
      >
        {props.children}
      </Dialog>
    </>
  );
};

export default PlayerDialog;
