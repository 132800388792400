import { classesToClassList } from "@components/solid/classLists";
import Soundwave from "@components/solid/common/Soundwave";
import { loadOrPlay } from "@components/solid/Player/AudioPlayer";
import {
  currentlyPlayingIndexPoint,
  indexPoints as indexPointsState,
  playingMediaId,
} from "@components/solid/Player/lib/playerStates";
import { nrkBulletSquare, nrkNote1 } from "@nrk/core-icons";
import { createVisibilityObserver } from "@solid-primitives/intersection-observer";
import { formatSecondsToHoursMinutesAndSeconds } from "@utils/date";
import {
  createEffect,
  For,
  Match,
  Show,
  Switch,
  type Component,
} from "solid-js";
import style from "./IndexPointsAndPlaylist.module.css";

const IndexPointsAndPlaylist: Component<{}> = () => {
  let componentRef: HTMLElement | undefined;
  let scrollElement: HTMLElement | undefined;
  const isComponentVisible = createVisibilityObserver({ threshold: 0 })(
    () => componentRef,
  );

  createEffect(() => {
    const currentlyPlayingIndexPointId = currentlyPlayingIndexPoint()?.id;
    if (!currentlyPlayingIndexPointId) return;

    const listItem = scrollElement?.querySelector(
      `#player--menu-index-poin-list-item-${currentlyPlayingIndexPointId}`,
    ) as HTMLElement;

    if (
      listItem &&
      scrollElement &&
      isComponentVisible() &&
      !isItemVisibleInScrollParent(scrollElement, listItem)
    ) {
      scrollElement.scrollTo({
        top: calculateScrollPosition(scrollElement, listItem),
      });
    }
  });

  return (
    <div class={style.IndexPointsAndPlaylist} ref={(el) => (componentRef = el)}>
      <Show
        when={indexPointsState() && indexPointsState().length > 0}
        fallback={<MissingIndexPointsMessage />}
      >
        <div
          ref={(el) => (scrollElement = el)}
          class={style.IndexPointsAndPlaylist__listWrapper}
        >
          <ul class={style.IndexPointsAndPlaylist__list}>
            <For each={indexPointsState()}>
              {(indexPoint) => {
                const isPlaying = () =>
                  currentlyPlayingIndexPoint() &&
                  indexPoint.id &&
                  currentlyPlayingIndexPoint()?.id === indexPoint.id;
                return (
                  <li
                    id={`player--menu-index-poin-list-item-${indexPoint.id}`}
                    class={style.IndexPointsAndPlaylist__list__item}
                  >
                    <button
                      classList={{
                        ...classesToClassList(
                          style.IndexPointsAndPlaylist__button,
                        ),
                        "nrk-typography-body": indexPoint.type === "music",
                        "nrk-typography-headline":
                          indexPoint.type == "non-music",
                        [style.playing]: isPlaying(),
                      }}
                      disabled={indexPoint.type === "music"}
                      onClick={() => {
                        const mediaIds = playingMediaId();
                        if (mediaIds) {
                          loadOrPlay(mediaIds, indexPoint.start);
                        }
                      }}
                    >
                      <Switch>
                        <Match when={isPlaying()}>
                          <span
                            class={style.IndexPointsAndPlaylist__button__icon}
                          >
                            <Soundwave />
                          </span>
                        </Match>
                        <Match when={indexPoint.type === "music"}>
                          <span
                            class={style.IndexPointsAndPlaylist__button__icon}
                            innerHTML={nrkNote1}
                          />
                        </Match>
                        <Match when={indexPoint.type === "non-music"}>
                          <span
                            class={style.IndexPointsAndPlaylist__button__icon}
                            innerHTML={nrkBulletSquare}
                          />
                        </Match>
                      </Switch>
                      <span class={style.IndexPointsAndPlaylist__textWrapper}>
                        <time
                          dateTime={formatSecondsToHoursMinutesAndSeconds(
                            indexPoint.start,
                          )}
                        >
                          {formatSecondsToHoursMinutesAndSeconds(
                            indexPoint.start,
                          )}
                        </time>
                        <span class={style.IndexPointsAndPlaylist__title}>
                          {indexPoint.type === "music" && indexPoint.artist
                            ? `${indexPoint.artist} - ${indexPoint.title}`
                            : indexPoint.title}
                        </span>
                      </span>
                    </button>
                  </li>
                );
              }}
            </For>
          </ul>
        </div>
      </Show>
    </div>
  );
};

export default IndexPointsAndPlaylist;

function calculateScrollPosition(
  scrollParent: HTMLElement,
  child: HTMLElement,
) {
  const parentHeight = scrollParent.clientHeight;
  const itemHeight = child.clientHeight;
  const itemOffsetTop = child.offsetTop;
  const scrollPosition = itemOffsetTop - parentHeight / 2 + itemHeight * 2;

  return scrollPosition;
}

function isItemVisibleInScrollParent(
  scrollParent: HTMLElement,
  child: HTMLElement,
) {
  const parentHeight = scrollParent.clientHeight;
  const itemHeight = child.clientHeight;
  const itemOffsetTop = child.offsetTop;
  const isItemVisible =
    itemOffsetTop >= scrollParent.scrollTop &&
    itemOffsetTop + itemHeight <= scrollParent.scrollTop + parentHeight;

  return isItemVisible;
}

function MissingIndexPointsMessage() {
  return <div>Programmet er dessverre ikke inndelt i tidspunkter</div>;
}
