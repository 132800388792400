import { classesToClassList } from "@components/solid/classLists";
import { generateNumberRange } from "@utils/generateNumberRange";
import { For, type Component } from "solid-js";
import style from "./DialogContent.module.css";
const playbackRates = generateNumberRange(0.5, 2.5, 0.1, 1);

export const DialogContent: Component<{
  currentRate: number;
  onSelect: (rate: number) => void;
  ref: (el: HTMLUListElement) => void;
}> = (props) => {
  return (
    <ul ref={(el) => props.ref(el)} class={style.DialogContent}>
      <For each={playbackRates}>
        {(rate) => {
          return (
            <li>
              <button
                data-active={rate === props.currentRate}
                classList={{
                  ...classesToClassList(
                    "nrk-button",
                    style["nrk-button-option"],
                  ),
                  [style["nrk-button-option--active"]]:
                    rate === props.currentRate,
                }}
                onClick={() => {
                  props.onSelect(rate);
                }}
              >
                {rate}x
              </button>
            </li>
          );
        }}
      </For>
    </ul>
  );
};
