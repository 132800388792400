import { generateNumberRange } from "@utils/generateNumberRange";
import { For, type Component } from "solid-js";
import style from "./Soundwave.module.css";

const bars = generateNumberRange(1, 6, 1);

const Soundwave: Component<{ size?: string }> = (props) => {
  return (
    <div
      class={style.Soundwave}
      style={{ width: props.size || "24px", height: props.size || "24px" }}
    >
      <For each={bars}>
        {(bar) => {
          return <div class={style[`bar${bar}`]} />;
        }}
      </For>
    </div>
  );
};

export default Soundwave;
