import { button } from "@components/solid/classLists";
import { type Component, type JSX } from "solid-js";

export const DialogButton: Component<{
  onClick: JSX.EventHandler<HTMLButtonElement, Event>;
  ref: (el: HTMLButtonElement) => void;
  buttonContent: string;
}> = (props) => {
  return (
    <button
      ref={props.ref}
      onClick={props.onClick}
      classList={button({ type: "ghost", variant: "icon" })}
    >
      <span innerHTML={props.buttonContent} />
    </button>
  );
};
