import { setPlaybackRate } from "@components/solid/Player/AudioPlayer";
import { DialogContent } from "@components/solid/Player/controls/PlaybackSpeed/DialogContent";
import { DialogHeading } from "@components/solid/Player/controls/PlaybackSpeed/DialogHeading";
import { ScrollButton } from "@components/solid/Player/controls/PlaybackSpeed/ScrollButton";
import PlayerDialog from "@components/solid/Player/controls/PlayerDialog";
import { createScrollPosition } from "@solid-primitives/scroll";
import {
  createEffect,
  createSignal,
  onCleanup,
  type Component,
} from "solid-js";
import style from "./PlaybackSpeed.module.css";

export const PlaybackSpeed: Component<{ rate: number }> = (props) => {
  let dialogRef: HTMLDialogElement | undefined;
  let dialogInnerRef: HTMLDivElement | undefined;
  let [dialogContentRef, setDialogContentRef] =
    createSignal<HTMLUListElement>();
  const [buttonsDisabledState, setButtonsDisabledState] = createSignal({
    up: false,
    down: false,
  });
  const scrollPosition = dialogContentRef
    ? createScrollPosition(dialogContentRef)
    : { x: 0, y: 0 };

  // Update the disabled state of the scroll buttons
  createEffect(() => {
    const ref = dialogContentRef();
    if (!ref) return;
    setButtonsDisabledState({
      up: scrollPosition.y <= 0,
      down:
        scrollPosition.y >= ref.scrollHeight - ref.offsetHeight &&
        scrollPosition.y !== 0,
    });
  });

  // Add mouseIsOver class to dialog when mouse is over
  createEffect(() => {
    const handleMouseEnter = (): void => {
      dialogRef?.classList.add(style.mouseIsOver);
    };
    const handleMouseLeave = (): void => {
      dialogRef?.classList.remove(style.mouseIsOver);
    };

    dialogInnerRef?.addEventListener("mouseenter", handleMouseEnter);
    dialogInnerRef?.addEventListener("mouseleave", handleMouseLeave);

    onCleanup(() => {
      dialogInnerRef?.removeEventListener("mouseenter", handleMouseEnter);
      dialogInnerRef?.removeEventListener("mouseleave", handleMouseLeave);
    });
  });

  return (
    <PlayerDialog
      buttonContent={`${props.rate}x`}
      dialogRef={(el) => {
        dialogRef = el;
      }}
      dialogClass={style.PlaybackSpeedDialog}
    >
      <div ref={(el) => (dialogInnerRef = el)} class={style.DialogInner}>
        <DialogHeading />
        <ScrollButton
          disabled={buttonsDisabledState().up}
          onClick={() => {
            const ref = dialogContentRef();
            if (!ref) return;
            ref.scrollBy({
              top: -ref.offsetHeight + 36,
              behavior: "smooth",
            });
          }}
          type="up"
        />
        <DialogContent
          ref={(el: HTMLUListElement) => {
            setDialogContentRef(el);
          }}
          currentRate={props.rate}
          onSelect={(rate) => {
            setPlaybackRate(rate);
            dialogRef?.close();
          }}
        />
        <ScrollButton
          disabled={buttonsDisabledState().down}
          onClick={() => {
            const ref = dialogContentRef();
            if (!ref) return;
            ref.scrollBy({
              top: ref.offsetHeight - 36,
              behavior: "smooth",
            });
          }}
          type="down"
        />
      </div>
    </PlayerDialog>
  );
};
