import {
  hasPlaybackSession,
  loadAudioPlayer,
} from "@components/solid/Player/AudioPlayer";
import {
  persistedLastPlayed,
  persistedMuted,
  persistedVolume,
} from "@components/solid/Player/lib/persistedPlayerStates";
import { playbackState } from "@components/solid/Player/lib/playerStates";
import { MiniPlayer } from "@components/solid/Player/MiniPlayer";
import { PlayerErrorMessage } from "@components/solid/Player/MiniPlayer/ErrorMessage";
import { Match, onMount, Switch, type ParentComponent } from "solid-js";

export const Player: ParentComponent = () => {
  onMount(() => {
    const persistedMediaIds = persistedLastPlayed();

    if (persistedMediaIds) {
      loadAudioPlayer(persistedMediaIds).then((playbackSession) => {
        persistedMuted() && playbackSession.mute();
        playbackSession.setVolume(persistedVolume());
      });
    }
  });
  return (
    <Switch>
      <Match when={playbackState.broken}>
        <PlayerErrorMessage />
      </Match>
      <Match when={hasPlaybackSession()}>
        <MiniPlayer />
      </Match>
    </Switch>
  );
};
