import { classesToClassList } from "@components/solid/classLists";
import { type ParentComponent } from "solid-js";
import styles from "./Dialog.module.css";

export const Dialog: ParentComponent<{
  ref: (ref: HTMLDialogElement) => void;
  class?: string;
}> = (props) => {
  return (
    <dialog
      ref={props.ref}
      onClick={(event) => {
        const dialogElement = event.target as HTMLDialogElement;
        if (dialogElement && dialogElement.tagName === "DIALOG") {
          dialogElement.close();
        }
      }}
      class={styles.Dialog}
      classList={classesToClassList(styles.Dialog, props.class)}
    >
      {props.children}
    </dialog>
  );
};
