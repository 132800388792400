export function calculateDialogPosition(
  buttonElement: HTMLButtonElement,
  dialogElement: HTMLDialogElement,
) {
  const buttonRect = buttonElement.getBoundingClientRect();
  const dialogComputedStyle = getComputedStyle(dialogElement);
  const dialogHeight = parseFloat(dialogComputedStyle.height);
  const dialogWidth = parseFloat(dialogComputedStyle.width);
  const top = `${buttonRect.top - dialogHeight}px`;
  const fromLeftcenteredOverButton =
    buttonRect.left + buttonRect.width / 2 - dialogWidth / 2;
  const fromLeftMax = document.documentElement.clientWidth - dialogWidth - 90;
  const left = Math.min(fromLeftcenteredOverButton, fromLeftMax) + "px";

  return {
    top,
    left,
  };
}
